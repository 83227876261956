import Carousel from 'react-multi-carousel';
import styled, { keyframes } from 'styled-components';

import ROUTES from 'config/routes';
import Trans from 'next-translate/Trans';
import Image from 'next/image';
import { LinkAButton } from 'shared/components/LinkButton';
import useTranslation from 'shared/hooks/useTranslation';

const Section = styled.section`
  align-items: center;
  background: linear-gradient(0deg, #19171d 0%, #000000 82.22%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 5rem 0rem;
`;

const gradient = keyframes`
{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
`;

const AnimatedGradientHeader = styled.h2`
  animation: ${gradient} 5s ease-in-out infinite;
  background: linear-gradient(263.37deg, #fccc51 0.21%, #fd6e40 52.03%, #e75b95 103.85%);
  background-size: 150%;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4.3rem;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const AnimatedSubHeader = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 4rem;
  text-align: center;
  margin-top: 1.6rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  min-width: 66rem;
`;

const Card = styled.div`
  background-color: white;
  color: #35353f;
  padding: 2.4rem;
  width: 34rem;
  min-height: 48rem;
  margin-left: 0.8rem;

  ul {
    margin-left: 3.2rem;
  }

  h4 {
    color: #a9a9aa;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.3rem;
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
  }

  h3 {
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 4.1rem;
  }

  li {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.9rem;
    margin-bottom: 2rem;

    div {
      transform: translateY(-6px);
    }
  }
`;

const ImageCenter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const LeftCard = styled(Card)`
  ul {
    list-style: square url('/img/home/comparison/true.png');
  }
`;

const RightCard = styled(Card)`
  ul {
    list-style: square url('/img/home/comparison/false.png');
  }
`;

const CarouselWrapper = styled.div`
  margin-top: 4rem;
  width: 100vw;

  .track {
    @media ${({ theme }) => theme.DEVICE.MD} {
      width: 70rem;
      margin: 0 auto;
    }
  }
`;

const StyledLinkButton = styled(LinkAButton)`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3.4rem;
  margin-top: 2rem;
  padding: 1.2rem 3rem;
  width: fit-content;
`;

const responsive = {
  bp1: {
    breakpoint: { min: 767, max: 2229 },
    items: 2,
  },
  bp2: {
    breakpoint: { min: 0, max: 766 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

function CreateAccountSection(): JSX.Element {
  const { t } = useTranslation('homePage');

  return (
    <Section>
      <AnimatedGradientHeader>{t('comparisonSection.advantage')}</AnimatedGradientHeader>
      <AnimatedSubHeader>{t('comparisonSection.comparison')}</AnimatedSubHeader>
      <ImageWrapper>
        <Image
          src="/img/home/comparison/competition-hands.png"
          alt=""
          quality={100}
          lazyBoundary="200px"
          height={669}
          width={812}
          objectPosition="center center"
        />
      </ImageWrapper>
      <CarouselWrapper>
        <Carousel
          responsive={responsive}
          showDots={false}
          draggable
          partialVisible
          removeArrowOnDeviceType={['bp1', 'bp2']}
          sliderClass="track"
        >
          <LeftCard>
            <ImageCenter>
              <Image
                src="/img/logos/logo-trustmate-2022-light.svg"
                alt=""
                quality={100}
                lazyBoundary="200px"
                height={40}
                width={174}
                objectPosition="left top"
              />
            </ImageCenter>
            <h4>{t('comparisonSection.qualityReview')}</h4>
            <ul>
              <li>
                <div>{t('comparisonSection.copywrite')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.decision')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.keyPhrases')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.websiteTraffic')}</div>
              </li>
              <li>
                <div>
                  <Trans
                    i18nKey="homePage:comparisonSection.characters"
                    values={{
                      charactersCount: 200,
                    }}
                    fallback="homePageEN:comparisonSection.characters"
                  />
                </div>
              </li>
            </ul>
          </LeftCard>
          <RightCard>
            <h3>{t('comparisonSection.reviewFromWeb')}</h3>
            <h4>{t('comparisonSection.shortReviews')}</h4>
            <ul>
              <li>
                <div>{t('comparisonSection.fewCharacters')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.searchNegativeReviews')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.notKeyPhrases')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.notAttractiveForGoogle')}</div>
              </li>
              <li>
                <div>{t('comparisonSection.noFeedback')}</div>
              </li>
            </ul>
          </RightCard>
        </Carousel>
      </CarouselWrapper>
      <StyledLinkButton
        onClick={
          process.env.NEXT_PUBLIC_APP_ENV === 'prod'
            ? "ga('send', 'event', 'button - redirect to register', 'click', 'page - homePage', 'section - comparison section');"
            : ''
        }
        href={ROUTES.REGISTER}
      >
        {t('navbar.freeTrial', 'common')}
      </StyledLinkButton>
    </Section>
  );
}

export default CreateAccountSection;
